export const WEB3_CONNECTION_REQUEST = "WEB3 CONNECTION REQUEST";
export const WEB3_CONNECTION_SUCCESS = "WEB3 CONNECTION SUCCESS";
export const WEB3_CONNECTION_FAILED = "WEB3 CONNECTION FAILED";
export const WEB3_UPDATE_ACCOUNT_SUCCESS = "WEB3 UPDATE ACCOUNT SUCCESS";

export const WEB3_REQUEST = "WEB3 REQUEST";

export const web3ConnectionRequest = (payload) => {
    return {
        type: WEB3_CONNECTION_REQUEST,
        payload
    }
}

export const web3ConnectionSucess = (payload) => {
    return {
        type: WEB3_CONNECTION_SUCCESS,
        payload
    }
}

export const web3ConnectionFailed = (payload) => {
    return {
        type: WEB3_CONNECTION_FAILED,
        payload
    }
}

export const web3UpdateConnectSuccess = (payload) => {
    return {
        type: WEB3_UPDATE_ACCOUNT_SUCCESS,
        payload
    }
}

export const web3connection = () => {
    return async dispatch => {
        dispatch(web3ConnectionRequest());
        if (window.ethereum){
            try {
                const accounts = await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                const networkId = await window.ethereum.request({
                    method: 'net_version'
                });
    
                if (networkId === 43113){
                    dispatch(web3ConnectionSucess({
                        account: accounts[0]
                    }));
    
                    window.ethereum.on('accountsChanged', (accounts) => {
                        dispatch(web3updateAccount(accounts[0]));
                    });

                    window.ethereum.on("chainChanged", () => {
                        window.location.reload();
                    });
                } else {
                    dispatch(web3ConnectionFailed("Change network to AVAX."))
                }
    
            } catch (err) {
                dispatch(web3ConnectionFailed("Something went wrong."))
            }

        } else {
            dispatch(web3ConnectionFailed("Intsall Metamask."))
        }
        
    }
}

export const web3updateAccount = (payload) => {
    return async dispatch => {
        dispatch(web3UpdateConnectSuccess(payload));
    }
}

export const web3Request = () => {
    return {
        type: WEB3_REQUEST
    }
}

export const web3req = () => {
    return dispatch => {
        dispatch(web3Request());
    }
}
