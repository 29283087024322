import * as actions from './blockchainActions';

const initialState = {
    loading: false,
    account: '',
    id: '',
    score: 0,
    isPlaying: false,
    isReward: false,
    rewardTx: '',
    errorMsg: ""
};

const web3Reducer = (state = initialState, action) => {
    switch(action.type){
        case actions.WEB3_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.WEB3_CONNECTION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.WEB3_CONNECTION_SUCCESS:
            return {
                ...state,
                loading: false,
                account: action.payload.account
            }
        case actions.WEB3_CONNECTION_FAILED:
            return {
                ...state,
                loading: false,
                errorMsg: action.payload
            }
        case actions.WEB3_UPDATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                account: action.payload
            }
        default:
            return state
    }
}

export default web3Reducer;