import React from "react";
import { useDispatch, useSelector } from "react-redux";


export function Header(){
    const dispatch = useDispatch();
    const blockchain = useSelector((state)=> state.web3Reducer);

    console.log('inside function',blockchain.account)
    return(
        <nav className="navbar navbar-expand-lg navbar-light fixed-top pl-5 pr-5">
            <a className="navbar-brand" href="/"><img className="text-neon" style={{width: "3em"}} alt="avaxgames logo" src={"./assets/img/hand-logo.webp"}/></a>
            <button className="navbar-toggler" data-toggle="collapse" data-target=".navbar-collapse">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-end">
                <ul className="navbar-nav">
                    <li className="nav-item p-2">
                        <a className="nav-link" href="#roadmap">Signalement</a>
                    </li>
                    <li className="nav-item p-2">
                        <a className="nav-link" href="#team">Projets</a>
                    </li>
                    <li className="nav-item p-2">
                        <a className="nav-link" href="#faq">Évènements</a>
                    </li>
                </ul>
            </div>
        </nav>
    )
}