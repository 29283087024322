import React from 'react';

export function Service(){

    return(
        <div id="team" className="container">
            <div className="row">
                <div className="col-lg-9 offset-lg-2 mb-10">
                    {/*<h2 className="black-italic white-text" style={{fontSize: "3.5em"}}>Team</h2><br/>
                    <p style={{fontFamily:"lato", fontSize: "1.5em"}}>Meet <b className="avaxgames-title-color">FRENSGAMES</b> Team.</p>
                    */}
                    <div className="row mt-10">
                        <div class="col-md-4 d-flex flex-column align-items-center">
                            <h3 className="black-italic mb-10" style={{fontSize: "2em"}}>Signalements</h3>
                            <p className="mqea-service-text center mb-10">
                                Vous détectez une nuisance, un dysfonctionnement ou une anomalie, faites un signalement pour ne pas laisser votre quartier se dégrader.
                            </p>
                            <img className="mb-5" style={{width: "100px"}} src="./assets/img/hand-signal.webp"/>
                            <a className="btn-join mb-10" href={`https://www.facebook.com/groups/468534784978625/`} target="_blank">Signaler</a>
                        </div>
                        <div class="col-md-4 d-flex flex-column align-items-center">
                            <h3 className="black-italic mb-10" style={{fontSize: "2em"}}>Projets</h3>
                            <p className="mqea-service-text center mb-10">
                                Vous avez une idée ou vous souhaitez soutenir des projets, participez et exprimez vous librement.
                            </p>
                            <img className="mb-5" style={{width: "100px"}} src="./assets/img/hand-project.webp"/>
                            <a className="btn-join mb-10" href={`https://www.facebook.com/groups/468534784978625/`} target="_blank">Proposer</a>
                        </div>
                        <div class="col-md-4 d-flex flex-column align-items-center">
                            <h3 className="black-italic mb-10" style={{fontSize: "2em"}}>Évènements</h3>
                            <p className="mqea-service-text center mb-10">
                                Organisez ou participez à des évènements. Rencontrez les autres habitants qui partagent les mêmes intérêts que vous.
                            </p>
                            <img className="mb-5" style={{width: "100px"}} src="./assets/img/hand-event.webp"/>
                            <a className="btn-join mb-10" href={`https://www.facebook.com/groups/468534784978625/`} target="_blank">Participer</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}