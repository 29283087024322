import React from "react";
import './App.css';
import * as s from "./styles/globalStyles";
import { useSelector } from 'react-redux';

import {Abovethefold, F2ESection, FirstSection, Home, Intro, P2ESection, SecondSection} from './components/Home';
import { Roadmap } from "./components/Home/Roadmap";
import { Footer } from "./components/Footer";
import { Team } from "./components/Home/Team";
import { Faq } from "./components/Home/Faq";
import { Header } from "./components/Header";
import { NFT } from "./components/Home/NFT";
import { Service } from "./components/Home/Service";

function App() {

  const blockchain = useSelector((state) => state.web3Reducer);

  return (
    <s.Screen>
      <Header/>
      <Home/>
      <Service/>
      <Footer/>
    </s.Screen>
  );
}

export default App;