import { applyMiddleware, combineReducers, createStore } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import { gameBoardReducer } from "./reducers";
import web3Reducer from "./blockchain/blockchainReducer";
import ThunkMiddleware from "redux-thunk"

const middlewares = [ThunkMiddleware];

const combinedReducers = combineReducers({
    gameBoardReducer,
    web3Reducer
});

const store = createStore(combinedReducers, composeWithDevTools(
    applyMiddleware(...middlewares)
));

export default store;