import React from "react";

export function Footer() {
    return (
        <footer className="container mb-5 mt-100">
            <div className="">
                <div className="row">
                    <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
                        <img alt="MonQuartierEnAction" style={{width: "60px"}} src={"./assets/img/hand-logo.webp"} />
                        <p>MonQuartierEnAction</p>
                        <p>contact@monquartierenaction.fr</p>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex justify-content-center">
                            <a className="p-3" target="_blank" href={"https://www.facebook.com/groups/468534784978625/"}>
                                <img alt="facebook" style={{width: "60px"}} src={"./assets/img/facebook-3d.webp"} />
                            </a>
                            <a className="p-3" target="_blank" href={"https://www.twitter.com/"}>
                                <img alt="facebook" style={{width: "60px"}} src={"./assets/img/twitter-3d.webp"} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                
            </div>
            <div className="d-flex justifiy-content-between">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </footer>
    );
}