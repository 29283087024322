export const NEW_GAME = "NEW GAME";
export const SAVE_SCORE = "SAVE SCORE";

export const newGame = (id,score) => {
    return ({
        type: NEW_GAME,
        payload: {id, score}
    })
}

export const saveScore = (payload) => {
    return({
        type: SAVE_SCORE,
        payload
    })
}

export const newGameAction = (id, score) => {
    return dispatch => {
        dispatch(newGame(id, score));
    }
}

export const saveScoreAction = ({id, score}) => {
    return dispatch => {
        dispatch(saveScore({id, score}));
    }
}