import * as actions from "./actions"


const initialStateBoard = {
    game: [{id: 0, score:0}],
    score: []
}

export const gameBoardReducer = (state=initialStateBoard , action) => {
    console.log('inside gameBoardReducer')
    switch(action.type){
        case actions.NEW_GAME:
            return {
                ...state,
                game: [...state.game, action.payload],
                score: [...state.score, action.payload.score]
            }
        case actions.SAVE_SCORE:
            return {
                ...state,
                game: [...state.game, action.payload]
            }
        default:
            return state
    }
}