import React from "react";
import PacmanLoader from "react-spinners/PacmanLoader";

export const Home = () => {

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12 d-flex justify-content-center align-items-center flex-column vh-100">
                    <div className="center" style={{maxWidth: "500px"}}>
                        <img className="text-neon" style={{width: "350px"}} alt="avaxgames logo" src={"./assets/img/hand-logo.webp"}/>
                        <p className="black-font mb-5" style={{color: "#29A8BE"}}>Signaler . Proposer . Participer</p>
                        <h1 className="black-italic" style={{fontSize: "2em"}}>Mon Quartier En Action</h1><br/>
                        <p style={{fontFamily:"lato", fontSize: "1.5em"}}><b className="mqea-title-color">MonQuartierEnAction</b> permet à tous les habitants de se sentir pleinement citoyens.</p><br/>
                        <p className="mb-5">Vous êtes bénévole, vous avez des idées ou vous souhaitez soutenir une action ? Grâce à <b className="mqea-title-color">MonQuartierEnAction</b> exprimez votre citoyenneté pour un meilleur quartier, plus vivant, plus agréable et plus animé.</p>
                    </div>
                    <a className="btn-join" href="https://www.facebook.com/groups/468534784978625/" target="_blank">Participer →</a>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="d-flex justify-content-center">
                        <a className="p-3" target="_blank" href={"https://www.facebook.com/groups/468534784978625/"}>
                            <img alt="facebook" style={{width: "60px"}} src={"./assets/img/facebook-3d.webp"} />
                        </a>
                        <a className="p-3" target="_blank" href={"https://www.twitter.com/"}>
                            <img alt="facebook" style={{width: "60px"}} src={"./assets/img/twitter-3d.webp"} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )

    
}

export function FirstSection(){
    return(
        <div className="container">
            <div className="row">
                <div className="col-md-6 d-flex justify-content-center align-items-center flex-column vh-100-d">
                    <div className="mt-10" style={{maxWidth: "500px"}}>
                        <h2 className="black-italic" style={{fontSize: "3.5em", marginTop: "150px"}}>Play2Earn 2.0 & GameFi</h2><br/>
                        <p style={{fontFamily:"lato", fontSize: "1.5em"}}><b className="avaxgames-title-color">FRENSGAMES</b> is dedicated to reviving classic videogames, by adding a web3 layer with multiplayer modes for a new and exciting gaming experience.</p>
                    </div>
                </div>
                <div className="col-md-6 d-flex justify-content-center align-items-center flex-column vh-100-d">
                    {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/SrmyBPBGXR4?autoplay=1&controls=0&loop=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe> */}
                    {/* <img style={{width:"100%", boxShadow: "6px 6px 0px #cb06d1"}} src={"./assets/img/avaxgames-trailer.gif"}/> */}
                    <video 
                            style={{width:"100%", boxShadow: "6px 6px 0px #cb06d1", marginTop: "150px"}}
                            preload="auto"
                            loop={true}
                            autoPlay={true}
                            muted={true}
                    >
                        <source src={"./assets/img/avaxgames-trailer.mp4"} type="video/mp4"></source>
                    </video>
                </div>
            </div>
        </div>
    );
}

export function SecondSection(){
    return(
        <div className="container">
            <div className="row">
                <div className="col-md-12 d-flex justify-content-center align-items-center flex-column vh-100">
                    <div className="center" style={{maxWidth: "600px"}}>
                        <p className="black" style={{color: "#09e3e8"}}>Play2Earn</p>
                        <h2 className="black-italic" style={{fontSize: "3.5em"}}>Winning Gameplays</h2><br/>
                        <p style={{fontFamily:"lato", fontSize: "1.5em"}}><b className="avaxgames-title-color">FRENSGAMES</b> offers three gameplay experiences:</p><br/>
                        <h3 className="black" style={{fontSize: "2.5em"}}>🕹Free2Free</h3>
                        <p style={{fontFamily:"lato", fontSize: "1.5em"}}>Anyone can access to the platform and any game without having to pay</p><br/>
                        <h3 className="black" style={{fontSize: "2.5em"}}>💸Free2Earn</h3>
                        <p style={{fontFamily:"lato", fontSize: "1.5em"}}>A player can earn tokens by achieving a target score in a game.</p><br/>
                        <h3 className="black" style={{fontSize: "2.5em"}}>🏆Pay2Earn (Tournament)</h3>
                        <p style={{fontFamily:"lato", fontSize: "1.5em"}}>Players pay an entry price to join the lobby of 100 players maximum. The tournament longs 10 minutes and the goal is to perform the highest score. The prize pool is shared with the top 25 players.</p><br/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function F2ESection(){
    return(
        <div className="container">
            <div className="row">
                <div className="col-md-12 d-flex justify-content-center align-items-center flex-column vh-100">
                    <div className="center" style={{maxWidth: "400px"}}>
                        <p className="black" style={{color: "#09e3e8"}}>Play2Earn</p>
                        <h2 className="black-italic" style={{fontSize: "3em"}}>💸Free2Earn</h2><br/>
                        <p style={{fontFamily:"lato"}}>The player pay only the blockchain fees to enter the <b className="avaxgames-title-color">Free2Earn</b>.</p><br/>
                        <h3 className="black" style={{fontSize: "2em"}}>⏳Timer</h3>
                        <p style={{fontFamily:"lato"}}>10 minutes.</p><br/>
                        <h3 className="black" style={{fontSize: "2em"}}>🎯Goal</h3>
                        <p style={{fontFamily:"lato"}}>Achieve a target score ! (To be defined and could be updated).</p><br/>
                        <h3 className="black" style={{fontSize: "2em"}}>🎟Price Entry</h3>
                        <p style={{fontFamily:"lato"}}>Free (Only network fees).</p><br/>
                        <h3 className="black" style={{fontSize: "2em"}}>🏆Reward</h3>
                        <p style={{fontFamily:"lato"}}>0.02 AVAX (Could be updated).</p><br/>
                        <h3 className="black" style={{fontSize: "2em"}}>🛑Limit</h3>
                        <p style={{fontFamily:"lato"}}>1 F2E/NFT Id/Day.</p><br/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function P2ESection(){
    return(
        <div className="container">
            <div className="row">
                <div className="col-md-12 d-flex justify-content-center align-items-center flex-column vh-100">
                    <div className="center" style={{maxWidth: "400px"}}>
                        <p className="black" style={{color: "#09e3e8"}}>Play2Earn</p>
                        <h2 className="black-italic" style={{fontSize: "3em"}}>🏆Tournaments</h2><br/>
                        <p style={{fontFamily:"lato"}}>The goal of a <b className="avaxgames-title-color">tournament</b> is to battle against other players and rank in the winners range. A <b className="avaxgames-title-color">tournament</b> is composed of 100 participants maximum.</p><br/>
                        <h3 className="black" style={{fontSize: "2em"}}>⏳Timer</h3>
                        <p style={{fontFamily:"lato"}}>10 minutes.</p><br/>
                        <h3 className="black" style={{fontSize: "2em"}}>🎯Goal</h3>
                        <p style={{fontFamily:"lato"}}>Perform the highest score !</p><br/>
                        <h3 className="black" style={{fontSize: "2em"}}>🎟Price Entry</h3>
                        <table class="table">
                            {/* <thead>
                                <tr>
                                    <th scope="col">#</th>
                                </tr>
                            </thead> */}
                            <tbody>
                                <tr>
                                    <td><p style={{fontFamily:"lato"}}>0.03 AVAX</p></td>
                                    <td><p style={{fontFamily:"lato"}}>0.1 AVAX</p></td>
                                    <td><p style={{fontFamily:"lato"}}>0.25 AVAX</p></td>
                                    <td><p style={{fontFamily:"lato"}}>0.5 AVAX</p></td>
                                    <td><p style={{fontFamily:"lato"}}>1 AVAX</p></td>
                                    <td><p style={{fontFamily:"lato"}}>2 AVAX</p></td>
                                    <td><p style={{fontFamily:"lato"}}>5 AVAX</p></td>
                                    <td><p style={{fontFamily:"lato"}}>10 AVAX</p></td>
                                </tr>
                            </tbody>
                        </table>
                        <h3 className="black" style={{fontSize: "2em"}}>🏆Reward</h3>
                        <p style={{fontFamily:"lato"}}>Winners: 20% of the participants.</p><br/>
                        <p style={{fontFamily:"lato"}}>Nul: 5% of the participants.</p><br/>
                        <p style={{fontFamily:"lato"}}>90% of the AVAX Prize Pool</p><br/>
                        <h3 className="black" style={{fontSize: "2em"}}>🛑Limit</h3>
                        <p style={{fontFamily:"lato"}}>No Limit.</p><br/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function Intro(){
    return (
        <div className="container" style={{marginTop: "50px"}}>
            <div className="row">
                <div className="col-md-6 d-mobile">
                    <div className="tenor-gif-embed" data-postid={21602878} data-share-method="host" data-aspect-ratio={1.77778} data-width="100%"></div>
                </div>
                <div className="col-md-6">
                    <div className="welcome" style={{marginTop: "70px"}}>
                        <p className="mp-subtitle">Welcome to the</p>
                        <h1 className="mp-title1">#AVAXGAMES Collection</h1>
                        <br/>
                        <p className="mp-text"><b className="avaxgames-title-color">FRENSGAMES</b> is a collection of 9,999 unique Social Avatars, randomly generated on Avalanche🔺 blockchain.</p>
                        <p className="mp-text"><b className="avaxgames-title-color">FRENSGAMES</b> is an NFT project community-driven to support <b style={{fontWeight: "600"}}>$JOE</b>.</p>
                        <p className="mp-text">Get yours & join the community  <b className="avaxgames-title-color">FRENSGAMES</b>.</p>
                        <br/><br/>
                    </div>
                    <div className="story" id="story">
                        <p className="mp-subtitle">About</p>
                        <h1 className="mp-title1">OUR STORY</h1>
                        <br/>
                        <p className="mp-text">
                            🌘 This is the story of 3 OGs who started their amazing <b style={{fontWeight: "600"}}>Avalanche</b>🔺 adventure since 2019. We believe in the network and his ability to disrupt the other chains.
                        </p>
                        <p className="mp-text">
                            🌗 Since July 2021, we onboarded on <b style={{fontWeight: "600"}}>TraderJoe</b> and since then we believe hard on Joseph and his ability to eat <b style={{fontWeight: "600"}}>$CAKE</b> 🥞 & ride <b style={{fontWeight: "600"}}>$UNI</b> 🦄.
                        </p>
                        <p className="mp-text">
                            🌖 Once the 9,999 <b className="avaxgames-title-color">FRENSGAMES</b> are gathered, phases 2 and 3 of the Roadmap will be deployed to reach the ultimate goal: <b style={{fontWeight: "600", fontSize: "16px"}}>JOSEPH to 24$</b> & share the <b style={{fontWeight: "600"}}>$JOE</b> rewards with the community.
                        </p>
                    </div>
                </div>
                <div className="col-md-6 d-desktop" style={{marginTop: "70px"}}>
                    <div className="tenor-gif-embed" data-postid={21602878} data-share-method="host" data-aspect-ratio={1.77778} data-width="100%"></div> 
                </div>
            </div>
        </div>
    );
}